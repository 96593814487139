// index.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
import vantEnLocale from 'vant/es/locale/lang/en-US' // element-ui lang
import vantZhLocale from 'vant/es/locale/lang/zh-CN'// element-ui lang
import enLocale from './en'
import zhLocale from './zh'
import { Locale } from 'vant'

Vue.use(VueI18n)

const messages = {
    en: {
        ...enLocale,
        ...elementEnLocale,
        ...vantEnLocale
    },
    zh: {
        ...zhLocale,
        ...elementZhLocale,
        ...vantZhLocale
    }
}
// 设置语言 选项 en | zh
let locale = Cookies.get('language') || process.env.VUE_APP_LANGUAGE ||  'zh';

if(!localStorage.getItem('language')){
    localStorage.setItem('language', locale) 
}else{
    locale = localStorage.getItem('language')
}

const i18n = new VueI18n({
    // 设置语言 选项 en | zh
    locale,
    // 设置文本内容
    messages
})


if(i18n.locale == 'en'){
    Locale.use(i18n.locale, vantEnLocale)
}else if(i18n.locale == 'zh'){
    Locale.use(i18n.locale, vantZhLocale)
}


export default i18n
